import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Account Initialization Process" prev={{
  name: 'Adding a Payment Method',
  path: '/adding-a-payment-method'
}} next={{
  name: 'Account Dashboard Intro',
  path: '/account-dashboard-intro'
}}>
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The account initialization process is when Simple Review Management begins syncing data with the other business accounts you have connected.`}</p>
    <p>{`The process can take several minutes depending on how much data you have in those accounts. In most cases you just need to wait while Simple RM works, but there is the potential that the process will need to pause for you to make a decision.`}</p>
    <p>{`The account initialization process begins by syncing your Google My Business account information.`}</p>
    <p>{`Once all the Google My Business data has been synced, Simple RM will move on to syncing QuickBooks Online data if you have a QuickBooks Online account connected.`}</p>
    <p>{`When the QuickBooks Online data sync is complete or if you do not have a QuickBooks Online account connected you will be redirected to your account dashboard.`}</p>
    <p>{`The following two scenarios will result in you needing to instruct Simple RM how to proceed.`}</p>
    <h2 {...{
      "id": "no-google-my-business-listing"
    }}>{`No Google My Business listing`}</h2>
    <p>{`If you have no Google My Business listing associated with the Google account you connected you will be presented with three options:`}</p>
    <ul>
      <li parentName="ul">{`Connected a different Google account `}</li>
      <li parentName="ul">{`Create a Google My Business in the Google account you have connected`}</li>
      <li parentName="ul">{`Claim ownership of an existing Google My Business listing with the account you have connected`}</li>
    </ul>
    <p>{`Choose one of the options and follow through with it to get the account initialization process going again.`}</p>
    <h2 {...{
      "id": "multiple-google-my-business-listings"
    }}>{`Multiple Google My Business listings`}</h2>
    <p>{`If you have a Google My Business listing associated with a Google user account and your Google user account is also associated with one or more Google Business Groups.`}</p>
    <p>{`In this case you will have to pick the account or group that you would like to proceed with. You can only pick one. Choose the account or group that contains the Google My Business listing you would like to request reviews for.`}</p>
    <p>{`When you make a selection the initialization process will recommence.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      